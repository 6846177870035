
.bikeDetailContainer {
    background-color: #000;
    color: #fff;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
}
.bikeDetailLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.bikeName {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 6px;
    margin-bottom: 20px;
    position: relative;
    background-color: #000;
}
.bikeNameAnimation {
    animation: bnAnimation 3s 1;
}
.bikeNameUnderLine {
    width: 360px;
    height: 5px;
    background-color: red;
    margin-bottom: 50px;
    border-radius: 5px;
}

.bikeImage {
    width: 70%;
    border-radius: 10px;
    box-shadow: 3px 3px 7px -4px rgba(255,255,255,0.7), -3px -3px 7px -4px rgba(255,255,255,0.7), 10px 10px 15px -4px rgba(255,255,255,0.1), -10px -10px 15px -4px rgba(255,255,255,0.1);
    position: relative;
}
.bikeImageAnimation {
    animation: biAnimation 1s 1;
}

.bikeDetailRightContainer {
    width: 50%;
}
.bikeDetailRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.bikeDetailRightAnimation {
    animation: bdrAnimation 2s 1;
}
.divContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    margin-bottom: 20px;
}
.mainName {
    margin-bottom: 50px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: 600;
}

.first {
    width: 45%;
}
.mid {
    width: 10%;
}
.end {
    width: 45%;
}
@keyframes bnAnimation{
    0% {
        color: #000;
        margin-bottom: -30px;
    }
    30% {
        letter-spacing: 15px;
        margin-bottom: -30px;
    }
    85% {
        letter-spacing: 8px;
        margin-bottom: -30px;
    }
    95% {
        text-shadow: #fff;
    }
}

@keyframes biAnimation {
    from {
        margin-left: -200px;
    }
    to {
        margin-left: 0;
    }
}

@keyframes bdrAnimation {
    from {
        margin-right: -200px;
    }
    to {
        margin-right: 0;
    }
}
.bikeDetailFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: #000;
}

.backButton {
    margin-right: 50px;
    margin-bottom: 20px;
    background-color: transparent;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: inset 3px 3px 10px -4px rgba(255,255,255,0.7),inset -3px -3px 10px -4px rgba(255,255,255,0.7);
}

@media (max-width: 1200px) {

}
@media (max-width: 900px) {

    .bikeDetailContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100vw;
    }
    .bikeName {
        font-size: 30px;
        letter-spacing: 3px;
    }
    .bikeDetailLeft {
        margin-top: 50px;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .bikeDetailRightContainer {
        width: 80%;
    }
    .bikeNameUnderLine {
        width: 300px;
        height: 5px;
        background-color: red;
    }
    .divContainer {
        width: 75%;
    }
    @keyframes bnAnimation{
        0% {
            color: #000;
            margin-bottom: -20px;
        }
        30% {
            letter-spacing: 8px;
            margin-bottom: -20px;
        }
        85% {
            letter-spacing: 5px;
            margin-bottom: -20px;
        }
        95% {
            text-shadow: #fff;
        }
    }
}
@media (max-width: 520px) {
    .bikeDetailLeft {
        margin-top: 50px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .bikeDetailRightContainer {
        width: 90%;
        font-size: 14px;
    }
    .bikeDetailRight {
        width: 100%;
    }

    .divContainer {
        width: 75%;
    }
    .bikeName {
        font-size: 24px;
        letter-spacing: 3px;
        background-color: #000;
    }
    .bikeNameUnderLine {
        width: 200px;
        height: 5px;
        background-color: red;
    }

}
@media (max-width: 320px) {

}