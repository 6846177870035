.yamahaContainer {
    background-image: url("../photoStore/blueBgYamahaUnsplash.jpg");
    background-size: cover;
}
.ducatiContainer {
    background-image: url("../photoStore/ducatiBgUnsplash.jpg");
    background-size: cover;
}
.hondaContainer {
    background-image: url("../photoStore/hondaBgUnsplash.jpg");
    background-size: cover;
}
.kawasakiContainer {
    background-image: url("../photoStore/kawasakiBgUnsplash.jpg");
    background-size: cover;
}


.bikeContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.bikeContainerBaby {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 1000px;
    flex-wrap: wrap;
    position: relative;
}
.bikeFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #000;
}

.backButton {
    margin-right: 50px;
    margin-bottom: 20px;
    background-color: transparent;
    border-radius: 5px;
    padding: 10px;
    box-shadow: inset 3px 3px 10px -4px rgba(255,255,255,0.7),inset -3px -3px 10px -4px rgba(255,255,255,0.7);
    color: #000;
}
@media (max-width: 1200px) {
    .bikeContainer {
        height: auto;
    }
    .bikeContainerBaby {
        width: 600px;
    }
}
@media (max-width: 760px) {
    .hondaContainer {
        background-image: url("../photoStore/hondaMobileBgUnsplash.jpg");
        background-size: cover;
    }
    .bikeContainerBaby {
        width: 300px;
    }
}