.choiseContainer {
    height: 100%;
    background-size: cover;
    background-image: url("../photoStore/brandBgUnsplash.jpg");
}
.choiseBrand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;

}
.choiseText {
    margin-top: 50px;
    font-size: 40px;
    font-weight: 700;
    margin-right: 20px;
    margin-bottom: 50px;
}
.choiseArrow {
    margin-top: 40px;
    font-size: 40px;
    font-weight: 700;
}
.brandContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.brandName {
    color: #fff;
    width: 400px;
    height: 60px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
}
.brandNameYamaha {
    border: 1px solid blue;
    box-shadow: inset 5px 5px 10px -4px rgba(0,0,255,0.7), inset -5px -5px 10px -4px rgba(0,0,255,0.7);
}
.brandNameDucati {
    box-shadow: inset 3px 3px 10px -4px rgba(255,0,0,0.7), inset -3px -3px 10px -4px rgba(255,0,0,0.7);
    border: 1px solid red;
}
.brandNameHonda {
    box-shadow: inset 3px 3px 10px -4px rgba(236,255,0,0.7), inset -3px -3px 10px -4px rgba(236,255,0,0.7);
    border: 1px solid yellow;
}
.brandNameKawasaki {
    box-shadow: inset 3px 3px 10px -4px rgba(0,255,0,0.7), inset -3px -3px 10px -4px rgba(0,255,0,0.7);
    border: 1px solid green;
    margin-bottom: 100px;
}
@media(max-width: 720px) {
    .choiseContainer {
        background-image: url("../photoStore/brandMobileBgUnsplash.jpg");
        background-size: cover;
    }
}

@media (max-width: 520px) {
    .choiseText {
        font-size: 25px;
        font-weight: 700;

    }
    .choiseArrow {
        margin-top: 25px;
        font-size: 30px;
    }
    .brandName {
        font-size: 15px;
        width: 50vw;
    }
}