.bikeAboutContainer {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutYamaha {
    font-size: 40px;
    font-weight: 700;
    height: 10%;
    margin-bottom: 50px;
}
.aboutMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}
.aboutContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}
.firstAbout {
    width: 45%;
}
.secondAbout {
    width: 10%;
}
.thirdAbout {
    width: 45%;
}

.aboutYamahaAnimation {
    animation: abyAnimation 2s 1;
}

@keyframes abyAnimation {
    0% {
        color: #000;
    }
    30% {
        letter-spacing: 25px;

    }
    85% {
        letter-spacing: 8px;
    }
    95% {
        text-shadow: #fff;
    }
}

@media (max-width: 760px) {
    .bikeAboutContainer {
        margin-top: 70px;
    }
    .aboutYamaha {
        font-size: 30px;
    }

    .aboutMainContainer {
        width: 60%;
        font-size: 14px;
    }
}

@media (max-width: 520px) {

    .bikeAboutContainer {
        margin-top: 70px;
    }
    .aboutYamaha {
        font-size: 30px;
    }

    .aboutMainContainer {
        width: 75%;
        font-size: 13px;
    }

    @keyframes abyAnimation {
        0% {
            color: #000;
        }
        30% {
            letter-spacing: 6px;
    
        }
        85% {
            letter-spacing: 2px;
        }
        95% {
            text-shadow: #fff;
        }
    }

}
@media (max-width: 320px) {


    .aboutYamaha {
        font-size: 25px;
    }

    .aboutMainContainer {
        width: 85%;
        font-size: 12px;
    }

    @keyframes abyAnimation {
        0% {
            color: #000;
        }
        30% {
            letter-spacing: 6px;
    
        }
        85% {
            letter-spacing: 2px;
        }
        95% {
            text-shadow: #fff;
        }
    }

}